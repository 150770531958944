

.App {
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
  background: #0a0c27;
}

.containFlex{
  display: flex;
}

.containFlex .display{
  flex-grow: .50;
  overflow-x: hidden;
  overflow-y: hidden;
}

.display:hover{
  cursor: pointer;
}


.themed-container{
  border:.1px solid transparent;
  box-shadow: 0px 0px 3px 1px #888888;
  margin: 1% 0;
  min-height: 1257.57px;
  background: white;
}

.navbar{
  padding: 1.5%;
  /* border:1px solid red; */
}

.scrolly{
  display: none;
}

@keyframes stick{
  0% {
    border: 3px solid white;
    padding: 1.5%;
  }

  50%{
    border: .5px solid white;
  }
  100%{
    border:none;
    padding: 1%;
  }
}

@keyframes load{
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}

.sticky{
  display: flex;
  position: fixed;
  top:0;
  width:100%;
  padding: 1% ;
  animation-name: stick;
  animation-duration: .7s;
  z-index: 3;
}


.visible{
  display: block;
}

.footerbar{
  padding-top: 3%;
  min-height: 300px;
  background-color: #7889e8;
  padding-bottom: 2%;
}


#quote{
  padding-bottom: 1%;
  width: 95%;
  margin: 0 auto;
  animation: load 1s;
}

.footerbar h2::before{
  content: '\f10d';
}

.footerbar h2::after{
  content: '\f10e';
}

.footerbar h2::before, .footerbar h2::after{
  font-family: FontAwesome;
  font-size: 40px;
  padding: 10px;
}

.stackHold{
  display: flex;
  justify-content: space-around;
  width: 480px;
  margin: 0 auto;
  background: white;
  border-radius: 5px;
  padding: .5% 0;
}

.contactIcons{
  width: 20%;
  margin: 0 auto;
  background-color: #323232;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
}

.media{
  padding: 5% 0;
  justify-content: space-evenly;
  width: 100%;
  margin: 1% 0;
  background-color: #f1f2f9;
  border-radius: 0.3rem;
  animation: load 1s;
}

.media-left img{
  height: 200px;
}

.media-body{
  text-align: left;
  max-width:20%;
  align-items: stretch;
}

.homeFlex{
  padding: 5% 0;
  width: 60%;
  margin: 0 auto;
  display: flex;
  text-align: left;
  animation: load 1s;
}

.homeChild{
  margin-right:10%;
}

.jumbotron{
  text-align:left;
  margin-top: 1%;
  animation: load 1s;
}

.jumbotron .badge{
  padding: 1%;
  text-transform: uppercase;
  margin: .8% .5%;
  border:2px solid transparent;
  position: relative;
  overflow: hidden;
}

.badge{
  padding: 1%;
  text-transform: uppercase;
  margin: .8% .5%;
  border:2px solid transparent;
}


.jumbotron .badge:hover{
  cursor: pointer;
}

.jumbotron .badge:hover::before{
  top:0;
  left:-100%;
  background-color: #6480af;
  /* background-color: red; */
  transform: translate(100%,0);
}

.jumbotron .badge:hover::after{
  background-color: #4e6488;
  /* background-color: black; */
  top: 50%;
  left:100%;
  transform: translate(-100%,0);
  transition-delay: .2s;
}

.jumbotron .badge::before, .jumbotron .badge::after{
  content: " ";
  left: 0;
  height: 50%;
  display: block;
  width: 100%;
  position: absolute;
  z-index: 1;
  transition: transform .3s cubic-bezier(.65,.05,.1,1);
}

.activee::before{
  top:0;
  left:0;
  background-color: #6480af;
}

.activee::after{
  background-color: #4e6488;
  top: 50%;
  left:0;
}


.jumbotron .badge span{
  position: relative;
  z-index: 2;
}

.portGrid{
  display: grid;
  grid-template-columns: 50% 50%;
  /* grid-template-rows: 25% 25% 25% 25%; */
  grid-template-rows: auto auto auto auto;
  /* grid-template-rows: 50% 50%; */
  justify-items: center;
  animation: load 1s;

}

.portGrid .card{
  width: 80%;
  margin:2% auto;
  text-align: left;
}


.card-img-top{
  height: 54%;
  width: 100%;
}

.contactPage{
  margin-top: 5.5%;
}


.contactForm{
  text-align: left;
  /* border:1px solid red; */
  animation: load 1s;
}

.contactFlex{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10%;
}

#contactPic{
  width: 40%;
}

#contactPic img{
  width: 80%;
}

.project{
  text-align: left;
}

.headerFlex{
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectImage{
  text-align: center;
  height: 40%;
}

.projectImage img{
  /* width:87.5%; */
  width: 100%;
}

.projectSection{
  padding: 2rem 2rem;
}

.caroeselBackground{
  background-color: rgba(50, 50, 50, .2);

}

.carouselHover:hover{
  box-shadow: 0px 0px 2px 1px black;

}

#gitLinks a{
  padding: 0 4px;
}



@media screen and (max-width:500px ){

  .media{
    padding: 5% 0;
    justify-content: space-evenly;
    flex-direction:column;
    align-items: center;
    width: 100%;
    margin: 1% 0;
    background-color: #f1f2f9;
  }

  .media-body{
    max-width: 100%;
    padding:5%
  }

  .homeFlex{
    flex-direction: column;
    width: 90%;
  }
  
  .homeChild{
    width: 100%;
  }



  .contactFlex{
    flex-direction: column;
    margin-top: 20%;
  }

  #contactPic{
    width: 100%;
  }

  .stackHold{
    width: 90%;
  }

  
}

@media screen and (max-width:767px){
  .portGrid{
    display: flex;
    flex-direction: column;
  }

  .themed-container{
    min-height: 762px;
  }

 .contactFlex{
   margin-top: 2%;
 }

}


@media screen and (min-width:768px) and (max-width:991px){

  .card button{
    margin-bottom:30%;
  }

  .portGrid .card{
    width:90%;
  }

  .themed-container{
    min-height: 762px;
  }

  .contactFlex{
    margin-top: 0%;
  }
}

.delayContainer{
  Height: 100%;
}

.bannerContainer{
  margin-top: 10%;
}

.bannerTitle{
  margin: 5px;

}



